.carousel {
    overflow: hidden;
    position: relative;
    height: 40px;
    border-radius: 4px;
    margin-bottom: 16px;
  }
  
  .inner {
    white-space: nowrap;
    transition: transform 0.3s;
    width: 100%;
    height: 100%;
  }
  
  .carousel-item {
    display: inline-flex;
    color: #fff;
    word-wrap:break-word;
    white-space: normal;
    padding: 10px;
    height: 100%;
  }

  .carousel-item > p {
    margin: 0;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 90%;
  }

  .carousel-item a {
    color: #fff;
    font-weight: bold;
  }
  
  .indicators {
    position: absolute;
    top: 4px;
    right: 0;
  }
  
  .indicators > button {
    margin: 5px;
    color: #fff;
  }
  
  .indicators > button.active {
    background-color: green;
    color: #fff;
  }
  