.stepper-wrapper-horizontal {
  display: flex;
  justify-content: space-between;
}
.stepper-wrapper-horizontal .step-wrapper {
  width: 23%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}
.stepper-wrapper-horizontal .step-number {
  border-radius: 50%;
  border: 1px solid grey;
  width: 28px;
  height: 28px;
  padding: 3px;
  text-align: center;
  margin-bottom: 1.2rem;
}
.stepper-wrapper-horizontal .divider-line {
  height: 1px;
  width: 60%;
  background-color: #bdbdbd;
  position: absolute;
  top: 20%;
  left: 70%;
}
.stepper-wrapper-horizontal .divider-line-2 {
  width: 296%;
}
.stepper-wrapper-horizontal .divider-line-3 {
  width: 125%;
}
.stepper-wrapper-horizontal .divider-line-4 {
  width: 70%;
}
.stepper-wrapper-horizontal .divider-line-5 {
  width: 60%;
}
.stepper-wrapper-vertical {
  width: 30%;
}
.stepper-wrapper-vertical .step-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 1.8rem;
  position: relative;
}
.stepper-wrapper-vertical .step-number {
  border-radius: 50%;
  min-width: 30px;
  width: 30px;
  height: 30px;
  padding: 4px;
  text-align: center;
  font-size: 85%;
}
.stepper-wrapper-vertical .step-description {
  margin-left: 1.2rem;
  padding-bottom: 3px;
}
.stepper-wrapper-vertical .divider-line {
  height: 26px;
  width: 1px;
  background-color: #bdbdbd;
  position: absolute;
  top: 104%;
  left: 14px;
}
.step-number-selected {
  border: 1px solid #bdbdbd;
  color: #fff;
}
.step-number-disabled {
  border: 1px solid #838383;
}
.step-description-active {
  font-weight: bold;
}
