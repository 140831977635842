.ui-provider {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.ui-table {
  min-height: 500px;
  overflow: auto;
  flex-grow: 1;
}

.ui-table > div {
  min-width: 950px;
}

.ui-table__row {
  padding: 1.8rem 0.8rem !important;
}

.ui-table__cell:last-child {
  flex-grow: unset;
  flex-basis: unset;
}

.ui-table__cell:last-child {
  min-width: 100px;
}

.ui-table__cell__content {
  width: 100%;
}

.ui-table__cell__content .ui-icon {
  justify-content: center;
  align-items: center;
  display: flex;
}

.ui-table__row {
  height: auto !important;
  padding: 12px !important;
}

.ui-dropdown__selecteditem {
  max-width: 10.75rem !important;
}

.stepper-container-horizontal {
  margin: 40px auto;
  padding: 30px;
  width: 70%;
}

.stepper-container-vertical {
  border: 1px solid lightsalmon;
  box-shadow: 0 0 13px #333;
  border-radius: 3px;
  margin: 40px auto;
  padding: 30px;
  width: 15%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.ui-carousel > div,
.ui-carousel .ui-carousel__itemscontainer,
.ui-carousel .ui-carousel__item,
.ui-carousel .ui-carousel__item img {
  width: 100%;
  height: auto;
}

.buttons-container {
  text-align: center;
}

.buttons-container button {
  margin: 0 1rem;
}

#callerId-dropdown ul li:nth-child(-n + 3) {
  font-weight: 600;
}

#callerId-dropdown ul li:nth-child(3) {
  margin-bottom: 4px;
}

#callerId-dropdown ul li:nth-child(4) {
  height: 28px;
  padding: 4px 12px 0px;
}

#callerId-dropdown ul li:nth-child(4)::before {
  content: "";
  display: block;
  background-color: #fff !important;
  width: 100%;
  position: absolute;
  top: -2px;
  left: -2px;
  font-size: 12px;
  padding: 2px 10px;
  font-weight: 700;
  border-top: 1px solid #edebe9;
}

@media screen and (max-width: 768px) {
  .stepper-container-horizontal,
  .toolbar .ui-toolbar,
  .toolbar > .ui-input input.ui-box,
  .toolbar .ui-toolbar > button,
  .toolbar .ui-toolbar button.ui-button,
  .publish-toolbar > button {
    width: 100% !important;
  }

  .toolbar,
  .toolbar .ui-toolbar,
  .publish-toolbar {
    display: block !important;
  }

  .toolbar {
    margin: 0 auto;
    /* min-height: 100px; */
    width: 100%;
  }

  .toolbar .ui-button {
    margin-top: 0 !important;
  }

  .toolbar > .ui-input {
    width: 82%;
  }

  .toolbar > .ui-button {
    margin: 0;
    padding: 0;
    min-width: 15%;
  }

  .toolbar .ui-toolbar {
    margin-top: 10px;
    display: none !important;
  }

  .toolbar .ui-toolbar button.ui-button {
    margin-top: 5px;
    max-width: 100%;
    padding: 0;
  }

  .publish-toolbar .ui-toolbar {
    justify-content: center !important;
  }

  .publish-toolbar .ui-alert {
    margin: 10px 0;
  }

  .stepper-container-horizontal {
    padding: 0;
  }

  .stepper-wrapper-vertical .step-description {
    margin-left: 10px;
  }

  .stepper-wrapper-vertical .divider-line {
    left: 13%;
  }

  .user-list li {
    padding: 12px 10px;
    border-bottom: 1px solid rgb(158, 157, 157);
  }

  .user-list > li > .ui-list__item__main {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-weight: 600;
  }

  .user-list .ui-list__itemcontent {
    font-size: 11px;
    font-weight: 500;
    margin-top: 10px;
  }

  .user-list > li .ui-list__itemheader {
    width: 92%;
  }

  .user-list > li .ui-text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: block;
    margin-bottom: -12px;
  }

  .user-list > li .ui-list__itemendmedia {
    display: block !important;
  }
}

.app-toolbar {
  margin: 0 -24px 32px;
  padding: 0 24px 10px;
  border-bottom: 1px solid #ebebeb;
}

.app-toolbar > .app-toolbar__header {
  cursor: pointer;
}

.app-toolbar > .app-toolbar__header:hover {
  color: rgb(98, 100, 167);
}

.ui-carousel a {
  color: #fff;
  font-weight: bold;
}
.ui-carrouselpaddles_container {
  height: 0;
  margin-top: -20px;
  position: relative !important;
}

.ui-carousel__itemscontainer {
  height: 120px;
  overflow: hidden;
}

.ui-carrouselpaddles_container > button:first-child{
  margin-bottom: 3.5rem;
}

.ui-carrouselpaddles_container > button:last-child{
  margin-bottom: 4.5rem;
}

.ads-icon > svg {
  height: 5rem;
  width: 5rem;
  color: #fff;
  position: absolute;
  left: 70px;
  top: 40px;
}