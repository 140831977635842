.dialogForm {
  margin-top: 15px;
  justify-content: flex-start !important;
}
.dialogForm input,
.dialogForm .ui-box{
  width: 100%;
}

.dialogForm .ui-icon {
  position: absolute;
  right: 0;
}

.updateUserDialog { 
  grid-template-columns: none !important;
  display: block !important;
}

.manage-users-dialog-actions button {
  width: auto;
}

@media screen and (max-width: 768px) {
  .manage-users-dialog-actions button {
    width: 100%;
  }
}
